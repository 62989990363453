.image-grid-widget {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  // max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;

  &__title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
    text-align: center;
  }

  &__subtitle {
    font-size: 1.5rem;
    margin: 1.5rem 0 1rem;
    color: #444;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5rem;
  }

  &__color-palette {
    margin-bottom: 2rem;
  }
}

.conference-tabs {
  width: 100%;
  margin-bottom: 2rem;
  padding: 60px;
  border-radius: 20px;

  .conference-tabs-list {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    overflow-x: auto;
    padding: 0.5rem;

    button {
      white-space: nowrap;
      margin: 0 0.25rem;
      transition: all 0.2s ease;

      &[data-state="active"] {
        background-color: #f0f0f0;
        font-weight: bold;
      }
    }
  }
}

.conference-info {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    margin: 0.5rem 0;
    color: #555;
  }
}

.conference-tab-content {
  padding: 1rem 0;
  position: relative;
}

.loading-state,
.error-state {
  text-align: center;
  padding: 3rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.error-state {
  color: #d32f2f;
}

.color-palette {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .color-item {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: calc(33.333% - 1rem);
    min-width: 250px;

    @media (max-width: 768px) {
      width: calc(50% - 1rem);
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .color-swatch {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    margin-right: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .color-info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .color-name {
    font-weight: 600;
    margin: 0 0 0.25rem;
    text-transform: capitalize;
  }

  .color-value {
    font-family: monospace;
    margin: 0;
    color: #666;
  }

  .color-picker {
    width: 100%;
    height: 30px;
    padding: 0;
    border: 1px solid #ddd;
    cursor: pointer;
  }
}

.color-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    margin: 0;
  }
}

.edit-button,
.save-button,
.cancel-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.edit-button {
  background-color: #f0f0f0;
  color: #333;

  &:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
}

.save-button {
  background-color: #4caf50;
  color: white;
  margin-right: 0.5rem;

  &:hover:not(:disabled) {
    background-color: #388e3c;
  }
}

.cancel-button {
  background-color: #f44336;
  color: white;

  &:hover:not(:disabled) {
    background-color: #d32f2f;
  }
}

.color-actions {
  display: flex;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 1.5rem;

  .image-item {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      .image-overlay {
        opacity: 1;
      }
    }

    &.uploading {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .image-container {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 1rem;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;

    span {
      color: white;
      font-weight: 500;
      text-align: center;
      padding: 0.5rem;
    }
  }

  .image-info {
    padding: 1rem;
  }

  .image-name {
    font-weight: 600;
    margin: 0 0 0.5rem;
    text-transform: capitalize;
    color: #333;
  }

  .image-url {
    font-size: 0.75rem;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  span {
    background-color: #333;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
  }
}
