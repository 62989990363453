@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --sidebar-background: 0 0% 98%;

    --sidebar-foreground: 240 5.3% 26.1%;

    --sidebar-primary: 240 5.9% 10%;

    --sidebar-primary-foreground: 0 0% 98%;

    --sidebar-accent: 240 4.8% 95.9%;

    --sidebar-accent-foreground: 240 5.9% 10%;

    --sidebar-border: 220 13% 91%;

    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

/* @layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
} */

@font-face {
  font-family: Gilroy;
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: GilroyMed;
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

* {
  font-family: "Gilroy";
  font-weight: 500;
  background: #ffffff;
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f1f1f1 !important;
}

.custom_time_picker {
  background-color: #f5f5f5;
  height: 45px;
  border-radius: 16px;
  margin-top: 6px;
  width: 100%;
}

.ant-picker-ok button span {
  background: transparent !important;
}

.ant-picker-input {
  background-color: #f5f5f5 !important;
}

/* ============================== */

/* Custom prefixed classes */
.sfasfS_switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.sfasfS_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sfasfS_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sfasfS_slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sfasfS_switch input:checked + .sfasfS_slider {
  background-color: #2196f3;
}

.sfasfS_switch input:focus + .sfasfS_slider {
  box-shadow: 0 0 1px #2196f3;
}

.sfasfS_switch input:checked + .sfasfS_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sfasfS_slider.round {
  border-radius: 34px;
}

.sfasfS_slider.round:before {
  border-radius: 50%;
}

.loader_KfnSdkCmel {
  width: 48px;
  height: 48px;
  border: 5px solid #f7ad00;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation_KfnSdkCmel 1s linear infinite;
}

@keyframes rotation_KfnSdkCmel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fex_button_1 {
  width: 110px;
  height: 30px;
  border-radius: 10px;
  background-color: #007bff; /* Button background color */
  color: white; /* Text color */
  font-size: 14px; /* Font size */
  border: none; /* Remove default border */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
  margin-top: 10px;
  margin-bottom: 10px;
}

.fex_button_1:hover {
  background-color: #0056b3; /* Darker background color on hover */
  transform: scale(1.05); /* Slight scale effect on hover */
}

.fex_button_1:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
}

.input-subfolder {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.input-subfolder:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.fex_button_remove {
  padding: 8px 16px; /* Padding for better spacing */
  background-color: #dc3545; /* Red background for 'Remove' */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 8px; /* Rounded corners */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
  margin-left: 10px; /* Margin to the left */
}

.fex_button_remove:hover {
  background-color: #c82333; /* Darker red background on hover */
  transform: scale(1.05); /* Slightly larger button on hover */
}

.fex_button_remove:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); /* Subtle shadow on focus */
}

.fex_folder_name {
  font-size: 16px; /* Set the font size */
  font-weight: bold; /* Make the folder name bold */
  color: #333; /* Dark text color for good readability */
  background-color: #f8f9fa; /* Light background to make it stand out */
  padding: 4px 8px; /* Add padding around the text */
  border-radius: 4px; /* Slight rounded corners */
  display: inline-block; /* Make sure the span doesn't break onto a new line */
  margin-right: 10px; /* Space to the right of the folder name */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.fex_folder_name:hover {
  background-color: #e2e6ea; /* Slightly darker background on hover */
  transform: scale(1.05); /* Slight scale effect on hover */
}

.fex_folder_name:focus {
  outline: none; /* Remove default outline on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Light blue shadow on focus */
}
/* */
.FileFolderManager_container {
  font-family: Arial, sans-serif; /* Clean and readable font */
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.FileFolderManager_header {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.FileFolderManager_label {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
}

.FileFolderManager_select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.FileFolderManager_button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.FileFolderManager_button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.FileFolderManager_files_section {
  margin-top: 20px;
}

.FileFolderManager_files_section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.FileFolderManager_files_section p {
  font-size: 16px;
  color: #777;
}

.FileFolderManager_file_item {
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FileFolderManager_remove_button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.FileFolderManager_remove_button:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.submit_save_button {
  padding: 10px 20px; /* Padding for spacing inside the button */
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 14px; /* Font size */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions for hover effects */
}

.submit_save_button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight scale effect when hovered */
}

.submit_save_button:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Light blue shadow on focus */
}
